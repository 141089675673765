import React, { useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  width: 100%;
  max-width: 100%; /* Full width */
  height: 90vh;
  overflow-y: auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  position: relative;

  /* Updated the modal's close button styling */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #222; /* Darker color for headings */
  }

  p,
  ul,
  li {
    color: #333; /* Darker color for body text */
    line-height: 1.6; /* Increase line-height for readability */
  }

  .price {
    color: #000; /* Dark black for the price text */
    font-weight: bold;
  }

  .sold {
    color: red; /* Ensure the SOLD text stands out */
    font-weight: bold;
  }

  .description {
    color: #555; /* Use a darker shade for the description */
  }
  .close {
    position: absolute;
    top: 15px; /* Adjust positioning */
    right: 20px;
    cursor: pointer;
    font-size: 30px; /* Increase font size */
    background-color: red; /* Add background for better visibility */
    color: white;
    padding: 10px; /* Padding for a larger button */
    border-radius: 50%; /* Circle shape */
    z-index: 2;
  }

  /* Adjusted the slider container height */
  .slider-container {
    margin-bottom: 10px; /* Reduced margin */
    height: 50vh; /* Reduced the slider height */
    max-height: 400px; /* Cap the height */
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .content {
    padding: 20px;
    font-size: 1.2rem;
  }

  h3 {
    margin-bottom: 8px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #555;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 10px;
    color: #777;
  }

  ul li {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .sold-status {
    font-weight: bold;
    color: red;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .highlight {
    color: #000;
    font-weight: bold;
  }
`;

const FullScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  .image-wrapper {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 50px;
      color: white;
      cursor: pointer;
      z-index: 10;
    }

    .left-arrow {
      left: 10px;
    }

    .right-arrow {
      right: 10px;
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 30px; /* Matching size */
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
  }
`;

const ArrowButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6); /* Remove shadow by adjusting opacity */
  padding: 15px;
  cursor: pointer;
  border-radius: 50%;
  color: white;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Remove box shadow */
  box-shadow: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Slightly darker on hover */
  }

  &.left {
    left: 20px; /* Proper spacing from the left */
  }

  &.right {
    right: 20px; /* Proper spacing from the right */
  }
`;

const Modal = ({ product, onClose }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentFullImageIndex, setCurrentFullImageIndex] = useState(0); // For full screen
  const sliderRef = useRef(null); // Ref for the modal carousel

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
  };

  const handleImageClick = (index) => {
    setCurrentFullImageIndex(index); // Start full screen from this image
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleFullNext = () => {
    // Handle only full-screen image navigation
    setCurrentFullImageIndex((prevIndex) =>
      prevIndex === product.imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleFullPrev = () => {
    // Handle only full-screen image navigation
    setCurrentFullImageIndex((prevIndex) =>
      prevIndex === 0 ? product.imageUrls.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    // Move the modal carousel images
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    // Move the modal carousel images
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  if (!product) return null;

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <span className="close" onClick={onClose}>
            &times;
          </span>

          {product.imageUrls && product.imageUrls.length > 0 && (
            <div className="slider-container">
              <Slider ref={sliderRef} {...settings}>
                {product.imageUrls.map((url, index) => (
                  <div key={index}>
                    <img
                      src={url}
                      alt={product.name}
                      onClick={() => handleImageClick(index)}
                    />
                  </div>
                ))}
              </Slider>
              <ArrowButton className="left" onClick={handlePrev}>
                &#9664;
              </ArrowButton>
              <ArrowButton className="right" onClick={handleNext}>
                &#9654;
              </ArrowButton>
            </div>
          )}

          <div className="content">
            <h3>{product.name}</h3>
            <p>
              Price:{" "}
              <span className="highlight">
                ${Number(product.price).toFixed(2)}
              </span>{" "}
              plus Taxes
            </p>
            <div className="sold-status">(((SOLD)))</div>
            <ul>
              {product.description.split(",").map((item, index) => (
                <li key={index}>{item.trim()}</li>
              ))}
            </ul>
          </div>
        </ModalContent>
      </ModalOverlay>

      {isFullScreen && (
        <FullScreenOverlay>
          <div className="image-wrapper">
            <img
              src={product.imageUrls[currentFullImageIndex]}
              alt={`Full screen ${product.name}`}
            />
            <span className="left-arrow" onClick={handleFullPrev}>
              &#9664;
            </span>
            <span className="right-arrow" onClick={handleFullNext}>
              &#9654;
            </span>
          </div>
          <span className="close" onClick={handleCloseFullScreen}>
            &times;
          </span>
        </FullScreenOverlay>
      )}
    </>
  );
};

export default Modal;
